import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dwnz';
  loggedIn: boolean;
  sidenav: any;

  constructor() {}

  ngOnInit() {
    this.loggedIn = false;
  }

  login() {
    this.loggedIn = !this.loggedIn;
  }
}
