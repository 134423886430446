<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1>Toniq Reports</h1>
      <div fxHide fxShow.gt-sm>
        <a mat-button [routerLink]="'/users'"> Users </a>
        <a mat-button [routerLink]="'/teams'"> Scheduled Reports </a>
      </div>
      <span class="header-spacer"></span>
      <a mat-button [routerLink]="'/login'"> Log In </a>
      <button mat-icon-button>
        <mat-icon [routerLink]="'/'" (click)="login()">account_circle</mat-icon>
      </button>
      <button mat-icon-button>
        <mat-icon [routerLink]="'/'">home</mat-icon>
      </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container >
  <mat-sidenav #sidenav mode="side" opened *ngIf="loggedIn">
    <mat-accordion class="example-headers-align" multi="true">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Dispensary
          </mat-panel-title>          
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item [routerLink]="'/dispensarySummary'"> Summary </a>
          <a mat-list-item [routerLink]="'/dispensaryPerformance'"> Performance </a>
          <a mat-list-item [routerLink]="'/dispensaryStock'"> Stock </a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Retail
          </mat-panel-title>          
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item [routerLink]="'/retailTransactions'"> Summary </a>
          <a mat-list-item [routerLink]="'/retailPerformance'"> Performance </a>
          <a mat-list-item [routerLink]="'/retailStock'"> Stock </a>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
   
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 88vh;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
